<template>
    <AppIcon
        v-bind="$props"
        :class="[ $style['chevron-right'], { [$style['chevron-right--rotate']]: $attrs['rotate'] } ]"
    >
        <template #default="{ color }">
            <path
                d="M8.58997 16.58L13.17 12L8.58997 7.41L9.99997 6L16 12L9.99997 18L8.58997 16.58Z"
                :fill="color"
            />
        </template>
    </AppIcon>
</template>

<style
    lang="sass"
    module
    scoped
>
    .chevron-right
        transition: transform 100ms var(--transition-default-ease)
        will-change: transform

        &--rotate
            transform: rotate(0.5turn)
</style>
